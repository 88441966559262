import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
const styles = require('./styles.module.scss')

const PrivacyPageTemplate = ({ data }: any) => {
  return (
    <section>
      <div className={styles.hero}>
        <h1>{data.frontmatter.title}</h1>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.pageBody}>
          <article className={styles.policyContent}>
            <MDXRenderer>{data.body}</MDXRenderer>
          </article>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPageTemplate
