import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PrivacyPageTemplate from '../components/PrivacyPolicyTemplate'
import Layout from '../components/Layout'

const PrivacyPolicy = ({ data }: any): JSX.Element => {
  return (
    <>
      <Helmet
        title={`${data.mdx.frontmatter.title} | ${data.site.siteMetadata.title}`}
      />
      <Layout>
        <PrivacyPageTemplate data={data.mdx} />
      </Layout>
    </>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query PrivacyPolicyQuery {
    mdx(slug: { eq: "privacy" }) {
      body
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
